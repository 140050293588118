@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.home-page {
    overflow-x: hidden;
}

.home-section,
.drivers-section,
.riders-section {
    padding: 6rem 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.home-section {
    background-color: #f8f9fa;
}

.drivers-section {
    background-color: #ffffff;
}

.riders-section {
    background-color: #ffffff;
    padding: 8rem 0;
}

.text-section h1 {
    color: #333;
    font-weight: 700;
}

.text-section p {
    color: #666;
    font-size: 1.2rem;
    line-height: 1.6;
}

.image-section img {
    max-height: 80vh;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.card {
    border: none;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.hover-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.card-icon {
    font-size: 3rem;
    color: #007bff;
}

.card-title {
    color: #333;
    font-weight: 600;
}

.card-text {
    color: #666;
}

.btn-primary,
.btn-outline-primary {
    border-radius: 50px;
    padding: 0.75rem 2rem;
    font-weight: 600;
    transition: all 0.3s ease;
}

.btn-primary:hover,
.btn-outline-primary:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.riders-section h2 {
    font-size: 3.5rem;
    color: #1a1a1a;
    margin-bottom: 4rem;
}

.rider-features {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 3rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    margin-left: 2rem;
}

.feature-icon {
    font-size: 1.5rem;
    color: #007bff;
    background-color: #e6f3ff;
    padding: 1rem;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
}

.rider-feature {
    margin-bottom: 2.5rem;
    transition: all 0.3s ease;
}

.rider-feature:hover {
    transform: translateX(10px);
}

.rider-feature h5 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.rider-feature p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 0;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 1rem 2.5rem;
    font-size: 1.1rem;
    margin-top: 2rem;
}

.riders-section img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16/10;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
    .home-section,
    .drivers-section,
    .riders-section {
        padding: 4rem 0;
    }

    .text-section h1 {
        font-size: 2.5rem;
    }

    .text-section p {
        font-size: 1rem;
    }
}

@media (max-width: 767px) {
    .home-section,
    .drivers-section,
    .riders-section {
        padding: 3rem 0;
    }

    .text-section h1 {
        font-size: 2rem;
    }

    .card {
        margin-bottom: 1.5rem;
    }

    .rider-features {
        padding: 1.5rem;
    }
}

