.card {
    border: none;
    border-radius: 15px;
}

.card-body {
    padding: 3rem !important;
}

h2 {
    color: #333;
    font-weight: 700;
}

.form-label {
    font-weight: 600;
    color: #555;
}

.form-control {
    border-radius: 10px;
    padding: 0.75rem 1rem;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
    transform: translateY(-2px);
}

.alert {
    border-radius: 10px;
}

@media (max-width: 768px) {
    .card-body {
        padding: 2rem !important;
    }
}

